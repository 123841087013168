/* eslint-disable no-undef*/
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';

import { CustomButton, CustomOrderedList } from '../MedicalForm/styles';

import QuestionComponent from '../QuestionComponentV2';

import { saveFitnessData, getFitnessData } from "../../redux/actions";
import { withTranslation } from 'react-i18next';

class FitnessForm extends Component {

  componentDidMount() {
    this.props.fetchFitnessData();
  }

  navigateUser = (e, value) => {
    e.preventDefault();
    const { history } = this.props;
    history.push(value);
  };

  render() {
    const { fitnessData, addFitnessData, hraQuestionsAnswersData } = this.props;
    return (
      <form>
        <CustomOrderedList>
          {
            hraQuestionsAnswersData['fitness'].map((section, index) => {
              let addedAnswers = '';
              fitnessData && fitnessData.length > 0 && fitnessData.map((data) => {
                if(data.question === section.question_id) {
                  addedAnswers = data.answer
                }
              });
              return (
                <QuestionComponent
                  key={section.question}
                  section={section}
                  saveData={addFitnessData}
                  addedAnswers={addedAnswers}
                  color={"#4798D9"}
                  questionNo={index+1}
                />
              )
            })
          }
        </CustomOrderedList>
        <CustomButton
          left
          onClick={(e) => this.navigateUser(e, '/wellness-assessment/lifestyle')}
        >
          {this.props.t("back")}
        </CustomButton>
        <CustomButton
          onClick={(e) => this.navigateUser(e, '/wellness-assessment/nutrition')}
          disabled={_.isUndefined(fitnessData) || fitnessData.length<hraQuestionsAnswersData['fitness'].length}
        >
          {this.props.t("next")}
        </CustomButton>
      </form>
    )
  }
}

FitnessForm.propTypes = {
  addFitnessData: PropTypes.func.isRequired,
  fetchFitnessData: PropTypes.func.isRequired,
  fitnessData: PropTypes.array,
  history: PropTypes.object.isRequired,
  hraQuestionsAnswersData: PropTypes.array,
  t: PropTypes.func
};

const mapStateToProps = (state) => ({
  fitnessData: state.wellBeingAssessment.fitnessData,
});

const mapDispatchToProps = (dispatch) => ({
  addFitnessData: (question, answer, isMultipleAnswers, count) => dispatch(saveFitnessData(question, answer, isMultipleAnswers, count)),
  fetchFitnessData: () => dispatch(getFitnessData())
});

export default connect(mapStateToProps, mapDispatchToProps)((withTranslation())(FitnessForm));