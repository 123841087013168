import styled, { css } from 'styled-components';

import { CustomCheckbox } from '../CreateEvent/styles';

const CustomRadioButton = styled.input`
  float: left;
  margin-top: 7px !important;
  margin-right: 6px !important;
  cursor: pointer;
`;

const StyledCheckbox = styled(CustomCheckbox)`
  padding-left: 30px;
  margin-top: 2px;
  margin-bottom: 2px;
  color: rgb(101, 104, 111);
`;

const SectionTitle = styled.span`
  font-size: 14px;
  display: inline-block;
  margin-bottom: 5px;
  font-weight: bold;
  color: rgb(101, 104, 111);
  text-transform: capitalize;
`;

const SectionContainer = styled.div`
  float: left;
  width: 100%;
  margin-top: 5px;
`;

const StyledButton = styled.button`
  display: inline-block;
  cursor: pointer;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.428571429;
  border-radius: 4px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #ffffff;
  background-color: #40c2fb;
  border-color: #40c2fb;
  position: absolute;
  bottom: 0;
  
  @media (max-width: 584px) {
    top: 20px;
    bottom: initial;
  }
`;

const CustomColumn = styled.div`
  width: ${({ main }) => main ? '100%' : '25%'};
  float: left;
  padding: 10px 5px;
  
  @media (max-width: 700px) {
    width: 100%;
    margin-top: 10px;
  }
  
  ${({ main }) => !main && css`
    max-width: 250px;
    position: relative;
    height: 175px;
  `}
`;

export { CustomRadioButton, StyledCheckbox, SectionTitle, SectionContainer, StyledButton, CustomColumn };